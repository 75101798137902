<template>
  <b-card>
    <confirm-modal ref="confirmModal" />
    <div class="d-flex justify-content-center flex-column">
      <create-location-modal ref="createLocationModal"> </create-location-modal>
      <b-row class="topbar mb-1">
        <b-col cols="3">
          <input
            type="text"
            class="form-control"
            :placeholder="T('Web.Generic.Search', 'Search')"
            v-model="search"
          />
        </b-col>
        <b-col>
          <b-button @click="openLocationCreateModal" variant="primary">
            {{ T("Web.Generic.CreateNew", "Create new") }}
          </b-button>
        </b-col>
        <loader class="loader" ref="loader"></loader>
      </b-row>
      <b-row>
        <b-table
        class="background-data-table"
          responsive
          show-empty
          :empty-text="T('Web.Generic.TableEmpty', 'No items to show')"
          :fields="tableHeadersFields"
          :items="filterted"
        >
          <template #cell(name)="data">
            <input
              @change="updateLocation(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.name"
              style="min-width: 300px"
            />
          </template>
          <template #cell(address)="data">
            <input
              @change="updateLocation(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.address"
            />
          </template>
          <template #cell(assetId)="data">
            <input
              @change="updateLocation(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.assetId"
            />
          </template>
          <template #cell(assetId2)="data">
            <input
              @change="updateLocation(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.assetId2"
            />
          </template>

          <template #cell(zip)="data">
            <input
              @change="updateLocation(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.zip"
            />
          </template>
          <template #cell(city)="data">
            <input
              @change="updateLocation(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.city"
            />
          </template>
          <template #cell(vatNumber)="data">
            <input
              @change="updateLocation(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.vatNumber"
            />
          </template>
          <template #cell(pNumber)="data">
            <input
              @change="updateLocation(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.pNumber"
            />
          </template>
          <template #cell()="data">
            <b-button
              variant="outline-danger"
              @click="removeLocation(data.item)"
            >
              {{ T("Web.Generic.Archive", "Archive") }}
            </b-button>
          </template>
        </b-table>
      </b-row>
    </div>
  </b-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CreateLocationModal from "@/components/modals/locations/CreateLocationModal.vue";

import loader from "@/components/layout/loader.vue";

import { BCard, BButton, BRow, BCol, BTable } from "bootstrap-vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BTable,
    CreateLocationModal,
    loader,
    ConfirmModal,
  },
  async created() {
    this.fetchLocations({ include: ["InfoCards"] });
  },
  data() {
    return {
      search: null,
    };
  },
  computed: {
    ...mapGetters({
      locations: "locations/list",
      loading: "locations/isLoading",
    }),
    filterted() {
      if (this.search == null || this.search == "") return this.locations;

      let filtered = this.locations.filter((e) => {
        return (
          e.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          e.address.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          e.zip.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          e.city.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          (e.pNumber != null &&
            e.pNumber.toLowerCase().indexOf(this.search.toLowerCase()) > -1) ||
          (e.vatNumber != null &&
            e.vatNumber.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
        );
      });

      return filtered;
    },
    tableHeadersFields() {
      return [
        {
          key: "name",
          label: this.T("Web.Generic.Name", "Name"),
          sortable: true,
        },
        {
          key: "assetId",
          label: "Id",
          sortable: true,
        },
        {
          key: "assetId2",
          label: "Id 2",
          sortable: true,
        },
        {
          key: "address",
          label: this.T("Web.Generic.Address", "Address"),
          sortable: true,
        },
        {
          key: "zip",
          label: this.T("Web.Generic.Zip", "Zip"),
          sortable: true,
        },
        {
          key: "city",
          label: this.T("Web.Generic.City", "City"),
          sortable: true,
        },
        {
          key: "vatNumber",
          label: this.T("Web.Generic.VatNo", "Vat number"),
          sortable: true,
        },
        {
          key: "pNumber",
          label: this.T("Web.Generic.PNo", "P number"),
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchLocations: "locations/fetchList",
      putLocation: "locations/update",
      deleteLocation: "locations/delete",
    }),
    async removeLocation(location) {
      if (
        !(await this.$refs.confirmModal.confirm(
          this.T(
            "Web.Generic.ArchiveConfirm",
            "Confirm that you are about to archive this item"
          )
        ))
      )
        return;
      this.$refs.loader.loading();
      await this.deleteLocation({ resourceId: location.id });
      this.$refs.loader.loadCompelete();
    },
    async openLocationCreateModal() {
      await this.$refs.createLocationModal.open();
    },
    async updateLocation(location) {
      this.$refs.loader.loading();
      await this.putLocation({ data: location });
      this.$refs.loader.loadCompelete();
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  position: relative;
  .loader {
    position: absolute;
    top: -15px;
    right: 0;
  }
}
.row {
  align-items: end;
}
.rowitem {
  &.offset-top {
    &:first-of-type {
      margin-top: 0;
    }
    margin-top: 10px;
  }
}
.offset-top {
  margin-top: 20px;
}
</style>